import style from "./review.module.scss";
import HexagonMobile from "./../hexagon_mobile/hexagon_mobile";
function Review() {
  return (
    <div className={style.review_section}>
      <div className={style.review_header}>
        <div className={style.review_header_hexagon}>
          <div className={style.review_header_hexagon_los_clutch}>
            <div className={style.review_header_hexagon_gambling}>
              <svg
                className={style.hexegon_size}
                xmlns="http://www.w3.org/2000/svg"
                width="116"
                height="128"
                viewBox="0 0 116 128"
                fill="none"
              >
                <path
                  d="M49.8655 2.77362C54.9809 -0.179749 61.2833 -0.17975 66.3987 2.77362L107.138 26.2946C112.254 29.248 115.405 34.706 115.405 40.6128V87.6547C115.405 93.5615 112.254 99.0195 107.138 101.973L66.3987 125.494C61.2833 128.447 54.9809 128.447 49.8655 125.494L9.12598 101.973C4.01059 99.0195 0.859375 93.5615 0.859375 87.6547V40.6128C0.859375 34.706 4.01059 29.248 9.12598 26.2946L49.8655 2.77362Z"
                  fill="url(#paint0_linear_1929_13121)"
                  fill-opacity="0.08"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1929_13121"
                    x1="58.1329"
                    y1="-2"
                    x2="58.1329"
                    y2="130.266"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#D9D9D9" />
                    <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <iframe
                className={style.hexegon_size}
                style={{
                  border: "none",
                }}
                width="132"
                height="132"
                src="https://shareables.clutch.co/share/badges/2320916/72896?utm_source=clutch_top_company_badge&utm_medium=image_embed"
                title="Top Clutch Web Developers Gambling California"
              ></iframe>

              <iframe
                className={style.hexegon_size}
                style={{
                  border: "none",
                }}
                width="132"
                height="132"
                src="https://shareables.clutch.co/share/badges/2320916/816?utm_source=clutch_top_company_badge&utm_medium=image_embed"
                title="Top Clutch Web Developers Gambling 2024"
              ></iframe>
              <svg
                className={style.hexegon_size}
                xmlns="http://www.w3.org/2000/svg"
                width="116"
                height="128"
                viewBox="0 0 116 128"
                fill="none"
              >
                <path
                  d="M49.8655 2.77362C54.9809 -0.179749 61.2833 -0.17975 66.3987 2.77362L107.138 26.2946C112.254 29.248 115.405 34.706 115.405 40.6128V87.6547C115.405 93.5615 112.254 99.0195 107.138 101.973L66.3987 125.494C61.2833 128.447 54.9809 128.447 49.8655 125.494L9.12598 101.973C4.01059 99.0195 0.859375 93.5615 0.859375 87.6547V40.6128C0.859375 34.706 4.01059 29.248 9.12598 26.2946L49.8655 2.77362Z"
                  fill="url(#paint0_linear_1929_13121)"
                  fill-opacity="0.08"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1929_13121"
                    x1="58.1329"
                    y1="-2"
                    x2="58.1329"
                    y2="130.266"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#D9D9D9" />
                    <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className={style.review_header_hexagon_los_angeles}>
              <svg
                className={style.hexegon_size}
                xmlns="http://www.w3.org/2000/svg"
                width="116"
                height="128"
                viewBox="0 0 116 128"
                fill="none"
              >
                <path
                  d="M49.8655 2.77362C54.9809 -0.179749 61.2833 -0.17975 66.3987 2.77362L107.138 26.2946C112.254 29.248 115.405 34.706 115.405 40.6128V87.6547C115.405 93.5615 112.254 99.0195 107.138 101.973L66.3987 125.494C61.2833 128.447 54.9809 128.447 49.8655 125.494L9.12598 101.973C4.01059 99.0195 0.859375 93.5615 0.859375 87.6547V40.6128C0.859375 34.706 4.01059 29.248 9.12598 26.2946L49.8655 2.77362Z"
                  fill="url(#paint0_linear_1929_13121)"
                  fill-opacity="0.08"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1929_13121"
                    x1="58.1329"
                    y1="-2"
                    x2="58.1329"
                    y2="130.266"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#D9D9D9" />
                    <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <iframe
                className={style.hexegon_size}
                style={{
                  border: "none",
                }}
                width="132"
                height="132"
                src="https://shareables.clutch.co/share/badges/2320916/18702?utm_source=clutch_top_company_badge&utm_medium=image_embed"
                title="Top Clutch Angular Developers Los Angeles 2024"
              ></iframe>
              <iframe
                className={style.hexegon_size}
                style={{
                  border: "none",
                }}
                width="132"
                height="132"
                src="https://shareables.clutch.co/share/badges/2320916/19952?utm_source=clutch_top_company_badge&utm_medium=image_embed"
                title="Top Clutch Nodejs Developers Los Angeles 2024"
              ></iframe>
              <iframe
                style={{
                  border: "none",
                }}
                width="132"
                height="132"
                src="https://shareables.clutch.co/share/badges/2320916/23235?utm_source=clutch_top_company_badge&utm_medium=image_embed"
                title="Top Clutch React Developers Los Angeles 2024"
                className={style.hexegon_size}
              ></iframe>
              <svg
                className={style.hexegon_size}
                xmlns="http://www.w3.org/2000/svg"
                width="116"
                height="128"
                viewBox="0 0 116 128"
                fill="none"
              >
                <path
                  d="M49.8655 2.77362C54.9809 -0.179749 61.2833 -0.17975 66.3987 2.77362L107.138 26.2946C112.254 29.248 115.405 34.706 115.405 40.6128V87.6547C115.405 93.5615 112.254 99.0195 107.138 101.973L66.3987 125.494C61.2833 128.447 54.9809 128.447 49.8655 125.494L9.12598 101.973C4.01059 99.0195 0.859375 93.5615 0.859375 87.6547V40.6128C0.859375 34.706 4.01059 29.248 9.12598 26.2946L49.8655 2.77362Z"
                  fill="url(#paint0_linear_1929_13121)"
                  fill-opacity="0.08"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1929_13121"
                    x1="58.1329"
                    y1="-2"
                    x2="58.1329"
                    y2="130.266"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#D9D9D9" />
                    <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className={style.review_header_hexagon_design_rush}>
            <div className={style.hexigon_design_rush}>
              <a href="https://www.designrush.com/agency/profile/prime-it-web-development">
                <img
                  alt="design_rush"
                  width={166}
                  height={220}
                  src="./assets/icons/design_rush.webp"
                  className={style.img_design_rush}
                  loading="lazy"
                />
              </a>
            </div>
          </div>
          <HexagonMobile />
        </div>

        <div className={style.review_header_logo}>
          <img
            alt="icon"
            className={style.about_icon}
            src="./assets/icons/about_icon.svg"
            loading="lazy"
          />
          <h2>Reviews & partnership</h2>
        </div>
      </div>
      <div className={style.review_body}>
        <div className={style.review_tablet}>
          <iframe
            className={style.review_tablet_item}
            title="Clutch Widget"
            src="https://widget.clutch.co/widgets/get/8?ref_domain=localhost&uid=2320916&rel_nofollow=true&reviews=2329281%2C2327194%2C2326027%2C2324609&ref_path=/"
            width="100%"
            height="670"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
        <div className={style.review_iphone}>
          <img
            src="./assets/icons/logo-black.svg"
            width={164}
            height={46}
            className={style.logo}
            alt="logo"
            loading="lazy"
          />
          <iframe
            className={style.review_iphone_item}
            title="Clutch Widget"
            src="https://widget.clutch.co/widgets/get/11?ref_domain=localhost&uid=2320916&rel_nofollow=true&ref_path=/"
            width="100%"
            height="450"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Review;
