import React from "react";
import style from "./hexagon-mobile.module.scss";

function HexagonMobile() {
  return (
    <div className={style.hexagon_mobile}>
      <div className={style.hexagon_mobile_top}>
        <iframe
          style={{
            border: "none",
          }}
          width="132"
          height="132"
          src="https://shareables.clutch.co/share/badges/2320916/23235?utm_source=clutch_top_company_badge&utm_medium=image_embed"
          title="Top Clutch React Developers Los Angeles 2024"
          className={style.hexegon_size}
        ></iframe>
      </div>
      <div className={style.hexagon_mobile_los_angeles}>
        <iframe
          style={{
            border: "none",
          }}
          width="132"
          height="132"
          src="https://shareables.clutch.co/share/badges/2320916/19952?utm_source=clutch_top_company_badge&utm_medium=image_embed"
          title="Top Clutch React Developers Los Angeles 2024"
          className={style.hexegon_size}
        ></iframe>
        <iframe
          style={{
            border: "none",
          }}
          width="132"
          height="132"
          src="https://shareables.clutch.co/share/badges/2320916/18702?utm_source=clutch_top_company_badge&utm_medium=image_embed"
          title="Top Clutch React Developers Los Angeles 2024"
          className={style.hexegon_size}
        ></iframe>
      </div>
      <div className={style.hexagon_mobile_gambling}>
        <iframe
          style={{
            border: "none",
          }}
          width="132"
          height="132"
          src="https://shareables.clutch.co/share/badges/2320916/816?utm_source=clutch_top_company_badge&utm_medium=image_embed"
          title="Top Clutch React Developers Los Angeles 2024"
          className={style.hexegon_size}
        ></iframe>
        <iframe
          style={{
            border: "none",
          }}
          width="132"
          height="132"
          src="https://shareables.clutch.co/share/badges/2320916/72896?utm_source=clutch_top_company_badge&utm_medium=image_embed"
          title="Top Clutch React Developers Los Angeles 2024"
          className={style.hexegon_size}
        ></iframe>
      </div>
      <div className={style.hexigon_svg}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          //   width="60"
          //   height="73"
          viewBox="0 0 60 73"
          fill="none"
        >
          <path
            d="M0 0L30 20.2154L60 0V55.5923L30 73L0 55.5923L0 0Z"
            fill="url(#paint0_linear_1942_13092)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1942_13092"
              x1="95.5"
              y1="-11"
              x2="13"
              y2="109"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D9D9D9" stop-opacity="0.11" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}

export default HexagonMobile;
