import React, {useCallback, useRef, useState} from 'react';
import './BeforeAfterSlider.css'; // Ensure correct path

const BeforeAfterSlider = ({size, positionLine}) => {
  const comparisonRef = useRef(null);
  const dragStartPosition = useRef(0);
  const [position, setPosition] = useState(positionLine);
  
  const updatePosition = useCallback((newPosition) => {
    setPosition(() => {
      return Math.max(positionLine, Math.min(newPosition, 82));
    });
  }, [positionLine]);
  
  const startDrag = (e) => {
    e.preventDefault();
    
    dragStartPosition.current = e.type.includes('touch') ? e.touches[0].clientX : e.clientX;
    
    const comparisonRect = comparisonRef.current.getBoundingClientRect();
    
    const move = (e) => {
      let clientX = e.type.includes('touch') ? e.touches[0].clientX : e.clientX;
      let diffX = clientX - dragStartPosition.current;
      
      let newPositionPercent = ((diffX / comparisonRect.width) * 100) + position;
      updatePosition(newPositionPercent);
    };
    
    const stopDrag = () => {
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('touchmove', move);
      document.removeEventListener('touchend', stopDrag);
    };
    
    // Add event listeners
    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('touchmove', move, { passive: false });
    document.addEventListener('touchend', stopDrag);
  };
  
  return (
    <div
      ref={comparisonRef}
      className="comparison-container"
      onMouseDown={startDrag}
      onTouchStart={startDrag}
    >
      <div className="comparison-image comparison-original">
        <img
          src="/assets/images/slider-original-background.png"
          loading="lazy"
          alt="Original"
          style={{ objectFit: "cover", height: "100%" }}
        />
        <a href="#" className="contact-button">
          Send Message
        </a>
      </div>
      <div
        className="comparison-image comparison-blurred"
        style={{
          left: `${position}%`,
          transform: "rotate(0deg) scale(1.1)",
        }}
      >
        <img
          src="/assets/images/second-layer-slider.png"
          loading="lazy"
          draggable={false}
          alt="Blurred"
          style={{ width: "100%", height: "105%", objectFit: "contain" }}
        />
        <div className="blurred-text-container"></div>
      </div>
      <div
        className="comparison-handle"
        style={{ left: `calc(${position}% + ${size}%)` }}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <div className="handle-content">
          <span className="arrow left-arrow">
            <img
              loading="lazy"
              src="/assets/images/left-arrow-slider.svg"
              alt="left-arrow"
            />
          </span>
          <span className="text">Swipe</span>
          <span className="arrow right-arrow">
            <img
              loading="lazy"
              src="/assets/images/right-arrow-slider.svg"
              alt="right-arrow"
            />
          </span>
        </div>
        <img
          src="/assets/images/swipe-slide-button.gif"
          className="handle-center-image"
          draggable={false}
          alt="Handle"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default BeforeAfterSlider;
