import React, { useEffect, useRef, useState, lazy } from "react";
import style from "./portfolio.module.scss";
import Loading from "../loading__ando";
import { motion, useAnimation } from "framer-motion";
import data from "./../../json/portoiloitem.json";
import BeforeAfterSlider from "../befor_after-slider/BeforeAfterSlider";

const PlaylistDisplay = lazy(() =>
  import("../playlistdisplay/playlistdisplay")
);
const About = lazy(() => import("../about/about"));
const PortfolioItem = lazy(() => import("./../portfoilo_item/portfoilo_item"));
// const GeoChart = lazy(() => import("../geo_chart/geo_chart"));
const Review = lazy(() => import("./../review/review"));
const MapChart = lazy(() => import("./../chartmap/chartmap"));

function Portfolio() {
  const apiKey = "AIzaSyBpJ329cLDAKb4pVfOOI6rS9ysodzQqJaw";
  const controls = useAnimation();
  const playlistId = "PL1ALfLPh2Ybf5KS4sq2PhhWHtcOyXlXRB";
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [isMoblie, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const primeVideoRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [desktopVideoOpen, setDesktopVideoOpen] = useState(false);
  const [showPlaylistDisplay, setShowPlaylistDisplay] = useState(true);
  const stickyTriggerRef = useRef(null);
  const [stickyplpaylistTrigger, setstickyplpaylistTrigger] = useState(true);
  const stickyplpaylistTriggerRef = useRef(null);
  const [slider, setSilder] = useState(false)
  const [size, setSize] = useState(0)
  const [positionLine, setPositionLine] = useState(14)
  
  useEffect(() => {
    if (window.innerWidth <= 1365) {
      setSilder(true);
    } else {
      if (window.innerWidth >= 1366 && window.innerWidth <= 1440) {
        setSize(15);
      } else if (window.innerWidth >= 1441 && window.innerWidth <= 1550) {
        setSize(18);
      } else if (window.innerWidth >= 1551 && window.innerWidth <= 1700) {
        setSize(11);
      } else if (window.innerWidth >= 1701 && window.innerWidth <= 1820) {
        setSize(12);
      } else if (window.innerWidth >= 1821 && window.innerWidth <= 1920) {
        setSize(14);
      } else if (window.innerWidth >= 1921) {
        setSize(15);
      }
      
      if (window.innerWidth >= 1366 && window.innerWidth <= 1440) {
        setPositionLine(15);
      } else if (window.innerWidth >= 1441 && window.innerWidth <= 1550) {
        setPositionLine(12);
      } else if (window.innerWidth >= 1551 && window.innerWidth <= 1700) {
        setPositionLine(22);
      } else if (window.innerWidth >= 1701 && window.innerWidth <= 1820) {
        setPositionLine(21);
      } else if (window.innerWidth >= 1821 && window.innerWidth <= 1920) {
        setPositionLine(16);
      } else if (window.innerWidth >= 1921) {
        setPositionLine(17);
      }
    }
  }, []);
  
  useEffect(() => {
    const checkIfMobile = () => {
      if (window.innerWidth <= 998) {
        setSelectedVideoId("sXnGZOTgkRQ");
        setIsMobile(window.innerWidth <= 998);
      }
    };

    checkIfMobile();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyTriggerRef.current) {
        const triggerTop = stickyTriggerRef.current.getBoundingClientRect().top;
        const shouldHidePlaylistDisplay = triggerTop < 0;
        setShowPlaylistDisplay(!shouldHidePlaylistDisplay);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSelectVideo = (videoId) => {
    console.log("id", videoId);
    setSelectedVideoId(videoId);
  };

  useEffect(() => {
    const animationscroll = () => {
      if (stickyTriggerRef.current) {
        const triggerTop = stickyTriggerRef.current.getBoundingClientRect().top;
        const shouldBeSticky = triggerTop < 0;

        if (isSticky !== shouldBeSticky) {
          setIsSticky(shouldBeSticky);
          if (shouldBeSticky) {
            controls.start("sticky");
          } else {
            controls.start("initial");
          }
        }
      }
    };

    window.addEventListener("scroll", animationscroll);
    return () => window.removeEventListener("scroll", animationscroll);
  }, [isSticky, controls]);

  useEffect(() => {
    const checkIfMobile = () => {
      if (window.innerWidth <= 998) {
        setSelectedVideoId("sXnGZOTgkRQ");
        setIsMobile(window.innerWidth <= 998);
      }
    };

    checkIfMobile();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyplpaylistTriggerRef.current) {
        const triggerTop =
          stickyplpaylistTriggerRef.current.getBoundingClientRect().top;
        const shouldHidePlaylistDisplay = triggerTop < 0;
        setstickyplpaylistTrigger(!shouldHidePlaylistDisplay);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const stickyVariants = {
    initial: {
      right: "20px",
      top: "5%",
      position: "relative",
      transition: { duration: 0.5 },
    },
    sticky: {
      transition: { duration: 0.6, delay: 0.05 },
      right: "45px",
      top: "10%",
      position: "fixed",
      transitionEnd: {
        top: "10%",
        right: "45px",
      },
    },
  };

  function openMobilePopup() {
    setShowMobilePopup(true);
    setIsLoading(false);
    setIsLoading(true);
    setIsLoading(false);
    setTimeout(() => {
      setIsLoading(true);
      console.log("false");
    }, 2000);
  }

  function closeMobilePopup() {
    setShowMobilePopup(false);
  }

  function handleCloseDesktopVideo() {
    setSelectedVideoId("");
  }

  return (
    <>
      <section className={style.portfolio_section}>
        <h2 className={style.visually_hidden}>Prime IT Inovation</h2>

        <div className={style.portfolio}>
          <div className={style.popup_btn}>
            <div className={style.btn_open_close}>
              {!showMobilePopup ? (
                <button
                  onClick={() => openMobilePopup()}
                  className={style.btn_open}
                >
                  <img
                    alt="youtube_logo"
                    width={60}
                    height={60}
                    src="/assets/icons/play.svg"
                    loading="lazy"
                  />
                </button>
              ) : (
                ""
              )}
            </div>
            {showMobilePopup && (
              <div className={style.playlist_popup}>
                <button
                  onClick={() => closeMobilePopup()}
                  className={style.btn_close}
                >
                  <img
                    width={70}
                    height={70}
                    alt="close-button"
                    src="/assets/icons/close.svg"
                    loading="lazy"
                  />
                </button>
                {!isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <PlaylistDisplay
                      playlistId={playlistId}
                      apiKey={apiKey}
                      videoSelect={handleSelectVideo}
                      autoplay="true"
                    />
                    {selectedVideoId && (
                      <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${
                          selectedVideoId ? selectedVideoId : "J5Co6wyJO1M"
                        }?autoplay=1`}
                        title="YouTube video player"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        className={style.popup_playlis}
                      ></iframe>
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          <div className={style.portfolio_header}>
            <div className={style.header_text_website}>
              <img
                src="/assets/icons/new_website.svg"
                alt="website_image"
                className={style.img_website}
                height={"auto"}
                loading="lazy"
              />
              <img
                src="/assets/icons/website-mobile.svg"
                alt="website_mobile_text"
                className={style.img_website_mobile}
                loading="lazy"
              />
            </div>
            <div
              ref={stickyTriggerRef}
              style={{ height: "0px", visibility: "hidden" }}
            ></div>

            <motion.div
              ref={primeVideoRef}
              initial="initial"
              animate={controls}
              variants={stickyVariants}
              className={style.prime_video}
            >
              <button
                className={style.btn_prime_video}
                onClick={() => {
                  setDesktopVideoOpen(!desktopVideoOpen);
                }}
              >
                {!desktopVideoOpen ? (
                  <img
                    src="/assets/icons/play.svg"
                    alt="play_button"
                    width={80}
                    height={80}
                    className={style.prime_video_btn}
                    loading="lazy"
                  />
                ) : (
                  <img
                    src="/assets/icons/close.svg"
                    alt="play_button"
                    width={80}
                    height={80}
                    className={style.prime_video_btn_close}
                    loading="lazy"
                  />
                )}
              </button>
              {!desktopVideoOpen ? (
                <iframe
                  width={!desktopVideoOpen ? 430 : 830}
                  height={240}
                  src="https://www.youtube.com/embed/sXnGZOTgkRQ?si=F532j5olk0TJP_q8"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ border: "1px solid #fff", borderRadius: "20px" }}
                  className={style.iframe_youtube}
                ></iframe>
              ) : (
                <iframe
                  width={830}
                  height={400}
                  src="https://www.youtube.com/embed/sXnGZOTgkRQ?si=F532j5olk0TJP_q8"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    border: "1px solid #fff",
                    borderRadius: "20px",
                    position: "absolute",
                    right: "25%",
                  }}
                ></iframe>
              )}
            </motion.div>

            <div className={style.portfolio_main}></div>
          </div>

          <div className={style.review}>
            {!slider && (
              <BeforeAfterSlider size={size} positionLine={positionLine} />
            )}
            <Review />
          </div>
          <div className={style.about}>
            <div
              ref={stickyplpaylistTriggerRef}
              style={{ height: "0px", visibility: "hidden" }}
            ></div>
            <div className={style.google_chart}>
              <div className={style.google_chart_title}>
                <img
                  alt="icon"
                  className={style.about_icon}
                  src="/assets/icons/about_icon.svg"
                  loading="lazy"
                />
                <h2>Visitor Visuals</h2>
              </div>
              {/* <GeoChart /> */}
              <MapChart />
            </div>

            <About />
          </div>

          <div className={style.portfolio_body}>
            {!isMoblie && (
              <div
                className={style.desktop_playlist}
                style={{
                  position: !stickyplpaylistTrigger ? "fixed" : "relative",
                  transform: !stickyplpaylistTrigger
                    ? "translateY(0px)"
                    : "translateY(0px)",
                  margin: !stickyplpaylistTrigger ? "auto 0 " : "0",
                }}
              >
                <PlaylistDisplay
                  playlistId={playlistId}
                  apiKey={apiKey}
                  videoSelect={handleSelectVideo}
                  autoplay="true"
                />
                {selectedVideoId && (
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${selectedVideoId}?autoplay=1`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={style.popup_playlis}
                  ></iframe>
                )}
                {selectedVideoId && (
                  <button
                    onClick={handleCloseDesktopVideo}
                    className={style.popup_playlis_close}
                  >
                    <img
                      src="/assets/icons/close.svg"
                      width={70}
                      height={70}
                      alt="close"
                      className=""
                      loading="lazy"
                    />
                  </button>
                )}
              </div>
            )}

            <div
              className={style.portfelio_items}
              style={{
                paddingLeft: !stickyplpaylistTrigger ? "270px" : "50px",
              }}
            >
              {data.map((item) => (
                <PortfolioItem
                  key={item.id}
                  title={item.title}
                  description={item.description}
                  imgSrc={item.imgSrc}
                  alt={item.alt}
                  link={item.link}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Portfolio;
