// Header.js
import React from "react";
import style from "./header.module.scss";

function Header() {
  return (
    <header id="logo" className={style.header}>
      <h1 style={{ display: "none" }}>PrimeIt</h1>

      <img
        src="./assets/icons/logo.svg"
        loading="lazy"
        w={288}
        height={80}
        className={style.logo}
        alt="logo"
      />

      <img
        src="./assets/images/hero-mobile.webp"
        className={style.hero_mobile}
        loading="lazy"
        alt="hero_image"
      />
      <img
        src="./assets/icons/innovative-mobile.svg"
        alt="inovative text"
        className={style.inovative_mobile}
        loading="lazy"
      />
    </header>
  );
}

export default Header;
